const content = [
  {
    video: require("../img/standard-bg.mp4"),
  },
];

function contentLoader() {
  return content;
}

export default contentLoader;
