import { v4 as uuidv4 } from "uuid";
export const content = {
  "Digital & Print": {
    description: require("./markdown/design/01-DigitalAndPrint.md"),
    items: [
      {
        title: "White Water — Style Pitch",
        alt: "Design for White Water ski resort",
        image: require("../img/portfolioItems/web/WhitewaterStyle.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/WhitewaterStyle_200.jpg"),
        init: require("../img/portfolioItems/web/init/WhitewaterStyle_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Pytorch Metric Learning Logo",
        alt: "Logo for Pytorch Metric Learning Library",
        image: require("../img/portfolioItems/web/pytorchmetriclearning.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/pytorchmetriclearning_200.jpg"),
        init: require("../img/portfolioItems/web/init/pytorchmetriclearning_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Pytorch Adapt  Logo",
        alt: "Logo for Pytorch Adapt Library",
        image: require("../img/portfolioItems/web/PytorchAdapt.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/PytorchAdapt_200.jpg"),
        init: require("../img/portfolioItems/web/init/PytorchAdapt_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Powerful Benchmarker Logo",
        alt: "Logo for Powerful Benchmarker Library",
        image: require("../img/portfolioItems/web/powerfulbenchmarker.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/powerfulbenchmarker_200.jpg"),
        init: require("../img/portfolioItems/web/init/powerfulbenchmarker_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "arXiv - Concept/Exploration",
        alt: "An exploration of how arXiv could look with an updated look",
        image: require("../img/portfolioItems/web/arXivConcept.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/arXivConcept_200.jpg"),
        init: require("../img/portfolioItems/web/init/arXivConcept_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Pay Per Click Ads and Email Signatures",
        alt: "Clear and communicative pay per click ads and email signatures.",
        image: require("../img/portfolioItems/web/SmallGraphicsSpread.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/SmallGraphicsSpread_200.jpg"),
        init: require("../img/portfolioItems/web/init/SmallGraphicsSpread_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "reebee Mothers Day Contest",
        alt: "Final designs and concepts for reebee Mothers Day Contents flyer thumbnail, banner, and landing pages.",
        image: require("../img/portfolioItems/web/ReebeeContestSpread.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/ReebeeContestSpread_200.jpg"),
        init: require("../img/portfolioItems/web/init/ReebeeContestSpread_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Nelson Daily Style Guideline",
        alt: "Redesign of Nelson Daily for a more modern look",
        image: require("../img/portfolioItems/web/NelsonDailyStyle.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/NelsonDailyStyle_200.jpg"),
        init: require("../img/portfolioItems/web/init/NelsonDailyStyle_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "University of Guelph — FARE and Research Websites",
        alt: "Design for University of Guelph (UoG) FARE department website, as well as Research Results website for professors",
        image: require("../img/portfolioItems/web/UniversitySpread.jpg"),
        thumbnail: require("../img/portfolioItems/web/thumbs/UniversitySpread_200.jpg"),
        init: require("../img/portfolioItems/web/init/UniversitySpread_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Moscone Tile Magazine Ad",
        alt: "Design for Moscone Tile",
        image: require("../img/portfolioItems/print/MosconeTileFullPage.jpg"),
        thumbnail: require("../img/portfolioItems/print/thumbs/MosconeTileFullPage_200.jpg"),
        init: require("../img/portfolioItems/print/init/MosconeTileFullPage_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "KCM Whitepaper",
        alt: "Whitepaper designed for cloud services provider, KCM, to be used by their sales staff",
        image: require("../img/portfolioItems/print/WhitePaperSpread.jpg"),
        thumbnail: require("../img/portfolioItems/print/thumbs/WhitePaperSpread_200.jpg"),
        init: require("../img/portfolioItems/print/init/WhitePaperSpread_init.jpg"),
        id: uuidv4(),
      },
    ],
  },
  "Album Art": {
    description: require("./markdown/design/02-AlbumArt.md"),
    items: [
      {
        title: "Half The Truth Is A Whole Lie",
        alt: "Half The Truth Is A Whole Lie Album Art",
        image: require("../img/portfolioItems/album-art/snp/halfthetruth/HalfTheTruthIsAWholeLieArt.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/halfthetruth/thumbs/HalfTheTruthIsAWholeLieArt_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/halfthetruth/init/HalfTheTruthIsAWholeLieArt_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Protection",
        alt: "Protection Album Art",
        image: require("../img/portfolioItems/album-art/snp/covers/SV1_03_Protection_1000.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/covers/thumbs/SV1_03_Protection_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/covers/init/SV1_03_Protection_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "No Harm",
        alt: "No Harm Album Art",
        image: require("../img/portfolioItems/album-art/snp/covers/SV1_04_NoHarm_1000.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/covers/thumbs/SV1_04_NoHarm_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/covers/init/SV1_04_NoHarm_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Tilt",
        alt: "Tilt Album Art",
        image: require("../img/portfolioItems/album-art/snp/covers/SV1_05_Tilt_1000.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/covers/thumbs/SV1_05_Tilt_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/covers/init/SV1_05_Tilt_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "I Can't Give Everything Away",
        alt: "I Can't Give Everything Away Album Art",
        image: require("../img/portfolioItems/album-art/snp/covers/SV1_06_ICantGiveEverythingAway_1000.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/covers/thumbs/SV1_06_ICantGiveEverythingAway_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/covers/init/SV1_06_ICantGiveEverythingAway_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "The Line Begins To Blur",
        alt: "The Line Begins To Blur Album Art",
        image: require("../img/portfolioItems/album-art/snp/covers/SV1_07_TheLineBeginsToBlur_1000.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/covers/thumbs/SV1_07_TheLineBeginsToBlur_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/covers/init/SV1_07_TheLineBeginsToBlur_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Strange & Primitive LP1",
        alt: "Strange & Primitive LP1 Album Art",
        image: require("../img/portfolioItems/album-art/snp/lpone/LPOneAndPhotos.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/snp/lpone/thumbs/LPOne_200.jpg"),
        init: require("../img/portfolioItems/album-art/snp/lpone/init/LPOne_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Audiograft: Melody in the Half-Light",
        alt: "Audiograft: Melody in the Half-Light Album Art",
        image: require("../img/portfolioItems/album-art/audiograft/MelodyInTheHalfLightAndPhoto.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/audiograft/thumbs/MelodyInTheHalfLight_200.jpg"),
        init: require("../img/portfolioItems/album-art/audiograft/init/MelodyInTheHalfLight_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Ephemeral Firelight",
        alt: "Ephemeral Firelight Album Art",
        image: require("../img/portfolioItems/album-art/kevinmusgrave/EphemeralFirelight.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/kevinmusgrave/thumbs/EphemeralFirelight_200.jpg"),
        init: require("../img/portfolioItems/album-art/kevinmusgrave/init/EphemeralFirelight_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Yellow Brick Road",
        alt: "Yellow Brick Road Album Art",
        image: require("../img/portfolioItems/album-art/aprilavenue/YellowBrickRoad.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/aprilavenue/thumbs/YellowBrickRoad_200.jpg"),
        init: require("../img/portfolioItems/album-art/aprilavenue/init/YellowBrickRoad_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "Pachelbel Peace",
        alt: "Pachelbel Peace Album Art",
        image: require("../img/portfolioItems/album-art/aprilavenue/PachelbelPeace.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/aprilavenue/thumbs/PachelbelPeace_200.jpg"),
        init: require("../img/portfolioItems/album-art/aprilavenue/init/PachelbelPeace_init.jpg"),
        id: uuidv4(),
      },
      {
        title: "You'd Be So Nice to Come Home to",
        alt: "You'd Be So Nice to Come Home to",
        image: require("../img/portfolioItems/album-art/aprilavenue/YoudBeSoNiceToComeHomeTo.jpg"),
        thumbnail: require("../img/portfolioItems/album-art/aprilavenue/thumbs/YoudBeSoNiceToComeHomeTo_200.jpg"),
        init: require("../img/portfolioItems/album-art/aprilavenue/init/YoudBeSoNiceToComeHomeTo_init.jpg"),
        id: uuidv4(),
      },
    ],
  },
};
